

// Gestion de la map

if (typeof donneesMap !== 'undefined') {

    var map = L.map('mapid', {
        center: [44.4667, 2.7167],
        zoom: 8
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Boucle création des markers
    jQuery.each(donneesMap, function (key, value) {

        if (value.latitude != null && value.longitude != null) {
            if (value.color != "") {
                var resIcon = L.icon({
                    iconUrl: 'img/map/' + value.cat + '/logo-map.png',
                    className: value.cat
                });

                var popuphtml = value.divmap;
                marker = L.marker([value.latitude, value.longitude], {icon: resIcon, alt: value.id});
                marker.addTo(map).bindPopup(popuphtml);

            }
        }

    });


    $("#clickLivres").click(function () {
        $(".livres").slideToggle();
    });

    $("#clickVendre").click(function () {
        $(".vendre").slideToggle();
    });

    $("#clickDemarrer").click(function () {
        $(".ademarrer").slideToggle();
    });

    $("#clickEnCours").click(function () {
        $(".encours").slideToggle();
    });

}

// Gestion du choix de l'agence pour les stats

$(document).ready(function () {
    $(".home-sel-agence").on('change', function(){
        let idAgence = $(".home-sel-agence").val();
        if (idAgence == '') {
            window.location.href = '/';
        } else {
            window.location.href = '/home/' + idAgence;
        }
    });
});

// Gestion des filtres de recherche

if (typeof surface !== 'undefined') {
    $(function () {
        $('#surface').selectpicker('val', surface);
    });
}

if (typeof budget !== 'undefined') {
    $(function () {
        $('#budget').selectpicker('val', budget);
    });
}

if (typeof localite !== 'undefined') {
    $(function () {
        $('#localite').selectpicker('val', localite);
    });
}

if (typeof type !== 'undefined') {
    $(function () {
        $('#type').selectpicker('val', type);
    });
}

if (typeof style !== 'undefined') {
    $(function () {
        $('#style').selectpicker('val', style);
    });
}

if (typeof niveaux !== 'undefined') {
    $(function () {
        $('#niveaux').selectpicker('val', niveaux);
    });
}

if (typeof searchetat !== 'undefined') {

    $(function () {
        $('#etats').selectpicker('val', searchetat);
    });
}

if (typeof searchstatut !== 'undefined') {

    $(function () {
        $('#statut').selectpicker('val', searchstatut);
    });
}

if (typeof searchcommercial !== 'undefined') {

    $(function () {
        $('#commercial').selectpicker('val', searchcommercial);
    });
}

if (typeof searchagence !== 'undefined') {

    $(function () {
        $('#agence').selectpicker('val', searchagence);
    });
}

// Si garage est cochée au chargement on affiche la surface sinon on cache

if( $('#garage').length )
{
    if ($("#garage")[0].checked === true)
    {
        $("#surface_garage").show();
    } else {
        $("#surface_garage").hide();
    }

    $(document).ready(function () {
        $("#garage").on("click",function () {
            //si garage est cochée on afficher la surface
            if ($("#garage")[0].checked === true)
            {
                $("#surface_garage").show();
            } else {
                $("#surface_garage").hide();
            }
        });
    });
}

// Si changement de projet principal dans les contact

if( $("input[name='projet_principal']").length )
{
    $(document).ready(function () {
        $("input[name='projet_principal']").on("click",function () {

            var projet = $("input[name='projet_principal']:checked").val();
            var contact_id = $("input[name='contact_id']").val();

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });

            $.ajax({
                url: "/contact/fiche/changement-projet-principal",
                type: "POST",
                data:{
                    projet:projet,
                    contact_id:contact_id,
                },
                success:function(response){
                    window.location.replace("/contact/fiche/" + contact_id);
                }
            });

        });
    });
}

$(document).ready(function () {
    $("#abandon-date").on('change', function(){
            if (confirm("Attention, vous êtes sur le point de passer cette fiche en statut \"Abandonné\" signifiant l’abandon définitif du projet. Confirmez-vous cette action ? "))
            { } else  {
                $("#abandon-date").val('');
            }
    });
});

// Ajout automatique du commercial et de l'agence dans la partie contact

$('#commercial-secteur').on('change', function() {
    let option = $(this).find(':selected');
    let idCom = option.data('commercial');
    $('#commercial').val(idCom);

    let optionA = $('#commercial').find(':selected');
    let idAgence = optionA.data('agence');
    $('#agence-' + idAgence).prop('checked', true);
});

$('#commercial').on('change', function() {
    let optionAg = $('#commercial').find(':selected');
    let idAg = optionAg.data('agence');
    $('#agence-' + idAg).prop('checked', true);
});


// Ecrire chiffres en lettres et

$(document).ready(function () {
    $('#cout_total_chiffre').on('change mouseleave', function(){
        calcule();
        var cout_total = $('#cout_total_chiffre').val();
        cout_total = cout_total.replace(',', '.');
        $('#cout_total_chiffre').val(cout_total);
        cout_total = parseFloat(cout_total);

        var cout_travaux = $('#cout_travaux_reserves').val();
        cout_travaux = cout_travaux.replace(',', '.');
        $('#cout_travaux_reserves').val(cout_travaux);
        cout_travaux = parseFloat(cout_travaux);
        var total = cout_total - cout_travaux;
        $('#prix_convenu').val(total);
    });
    $('#cout_travaux_reserves').on('change mouseleave', function(){
        var cout_total = $('#cout_total_chiffre').val();
        cout_total = cout_total.replace(',', '.');
        $('#cout_total_chiffre').val(cout_total);
        cout_total = parseFloat(cout_total);
        var cout_travaux = $('#cout_travaux_reserves').val();
        cout_travaux = cout_travaux.replace(',', '.');
        $('#cout_travaux_reserves').val(cout_travaux);
        cout_travaux = parseFloat(cout_travaux);
        var total = cout_total - cout_travaux;
        $('#prix_convenu').val(total);
    });
});

var res, plus, diz, s, un, mil, mil2, ent, deci, centi, pl, pl2, conj;

var t=["","Un","Deux","Trois","Quatre","Cinq","Six","Sept","Huit","Neuf"];
var t2=["Dix","Onze","Douze","Treize","Quatorze","Quinze","Seize","Dix-sept","Dix-huit","Dix-neuf"];
var t3=["","","Vingt","Trente","Quarante","Cinquante","Soixante","Soixante","Quatre-vingt","Quatre-vingt"];

function calcule(){
    document.getElementById("cout_total_chiffre").onkeyup=function(){
        document.getElementById("cout_total_lettre").value=trans(this.value)
    }
}

// traitement des deux parties du nombre;
function decint(n){

    switch(n.length){
        case 1 : return dix(n);
        case 2 : return dix(n);
        case 3 : return cent(n.charAt(0)) + " " + decint(n.substring(1));
        default: mil=n.substring(0,n.length-3);
            if(mil.length<4){
                un= (mil==1) ? "" : decint(mil);
                return un + mille(mil)+ " " + decint(n.substring(mil.length));
            }
            else{
                mil2=mil.substring(0,mil.length-3);
                return decint(mil2) + million(mil2) + " " + decint(n.substring(mil2.length));
            }
    }
}

// traitement des nombres entre 0 et 99, pour chaque tranche de 3 chiffres;
function dix(n){
    if(n<10){
        return t[parseInt(n)]
    }
    else if(n>9 && n<20){
        return t2[n.charAt(1)]
    }
    else {
        plus= n.charAt(1)==0 && n.charAt(0)!=7 && n.charAt(0)!=9 ? "" : (n.charAt(1)==1 && n.charAt(0)<8) ? " et " : "-";
        diz= n.charAt(0)==7 || n.charAt(0)==9 ? t2[n.charAt(1)] : t[n.charAt(1)];
        s= n==80 ? "s" : "";

        return t3[n.charAt(0)] + s + plus + diz;
    }
}

// traitement des mots "cent", "mille" et "million"
function cent(n){
    return n>1 ? t[n]+ " Cent" : (n==1) ? " Cent" : "";
}

function mille(n){
    return n>=1 ? " Mille" : "";
}

function million(n){
    return n>=1 ? " Millions" : " Million";
}

// conversion du nombre
function trans(n){

    // vérification de la valeur saisie
    if(!/^\d+[.,]?\d*$/.test(n)){
        return "L'expression entrée n'est pas un nombre."
    }

    // séparation entier + décimales
    n=n.replace(/(^0+)|(\.0+$)/g,"");
    n=n.replace(/([.,]\d{2})\d+/,"$1");
    n1=n.replace(/[,.]\d*/,"");
    n2= n1!=n ? n.replace(/\d*[,.]/,"") : false;

    // variables de mise en forme
    ent= !n1 ? "" : decint(n1);
    deci= !n2 ? "" : decint(n2);
    if(!n1 && !n2){
        return  "Entrez une valeur non nulle!"
    }
    conj= !n2 || !n1 ? "" : "  et ";
    euro= !n1 ? "" : !/[23456789]00$/.test(n1) ? " Euro" : "s Euro";
    centi= !n2 ? "" : " centime";
    pl=  n1>1 ? "s" : "";
    pl2= n2>1 ? "s" : "";

    // expression complète en toutes lettres
    return (" " + ent + euro + pl + conj + deci + centi + pl2).replace(/\s+/g," ").replace("cent s E","cents E") ;

}

// Descriptif détaille - Equipements

$(document).ready(function () {

    $('.equipement-quantite').on('change',function(){
        var equipId = $(this).data("id");
        var resultat = $(this).val().replace(",",".") * parseFloat($('#prix-unitaire-option-' + equipId).val());
        $('#prix-total-' + equipId).attr('value', resultat.toFixed(2));
        $('#prix-total-' + equipId).val(resultat.toFixed(2));
        $(this).attr('value', $("#quantite_option").val());
        $('#forfait-option-' + equipId).attr('value', '');
        $('#forfait-option-' + equipId).val('');
    });

    $('.equipement-forfait').on('change',function(){
        var equipId = $(this).data("id");
        $('#prix-total-' + equipId).attr('value', '');
        $('#prix-total-' + equipId).val('');
        $('#quantite-option-' + equipId).attr('value', '');
        $('#quantite-option-' + equipId).val('');
    });

    $('.equip-type').on('click',function(){
        var typeId = $(this).data("id-type");
        $('#equipement-type-' + typeId).slideToggle("slow");
    });

    $('.checkbox-option').on('click',function(){

        var optionId = $(this).data("id-option");

        if( $(this).is(':checked') ){
            $('#equipement-options-' + optionId).show("slow");
            $('#equipment_' + optionId).prop("checked", false);

        } else {
            $('#equipement-options-' + optionId).hide("slow");
        }

    });

    $('.checkbox-equipement').on('click',function(){

        var equipementId = $(this).data("id-equipement");

        if( $(this).is(':checked') ){
            $('#option_' + equipementId).prop("checked", false);
            $('#equipement-options-' + equipementId).hide("slow");
        }

    });

    $('.custom-file-input').on('change', function () {
        var id = $(this).data("id");
        //var parent = $(this).parent();
        $('#id-' + id).html('1 fichier selectionné');
        //$(parent).children('.custom-file-label').html('1 fichier selectionné');
    })

});

// Test avant envoi email prise de rdv contact

if( $('#enregrdv').length )
{
    $(document).ready(function () {
        $("#enregrdv").on("click",function (e) {
            var rdvdate = $("#rdv-date").val();
            var rdvhour = $("#rdv-hour").val();
            var dom_rdv = $("#dom_rdv").val();

            if (rdvdate === '' || rdvhour === '')
            {
                alert('Vous devez renseigner une date et une heure pour le RDV client !');
                e.preventDefault();
            } else {
                if( $("#dom_rdv").is(':checked') || $("#bur_rdv").is(':checked') ){ } else {
                    alert('Vous devez renseigner un lieu pour le RDV client !');
                    e.preventDefault();
                }
            }

        });
    });
}


// ----------------------------
// ADMIN
// ----------------------------

// Descriptif détaille - Equipements

// Détection de la sélection du sous type

if (typeof idEquipementActif !== 'undefined') {

if (idEquipementActif != "") {

    var idEquipement = $('#id_equipement').val();
    var affichage = '';
    var selectList = "<select name='id_sous_type' class=selectpicker>";
    selectList += "<option value=0> Aucun sous type </option>";

    $.each( ssTypeEquipements, function( key, value ) {

        if (key == idEquipement) {

            affichage = 'ok';

            $.each( value, function( k, ssTypeName ) {
                if (idSsType == ssTypeName['id_sous_type']) {
                    selectList += "<option value=" + ssTypeName['id_sous_type'] + " selected=selected>" + ssTypeName['name_sous_type'] + "</option>";
                } else {
                    selectList += "<option value=" + ssTypeName['id_sous_type'] + ">" + ssTypeName['name_sous_type'] + "</option>";
                }
            });
        }
    });

    selectList += "</select>";

    if (affichage == 'ok') {
        $('#my-container').html(selectList);
    } else {
        selectList = '';
        $('#my-container').html(selectList);
    }
}
}

$(document).ready(function () {

    // Détection de la sélection du sous type et mise à jour si changement de type
    $('#id_equipement').on('change',function(){

        var idEquipement = $(this).val();

        var affichage = '';

        var selectList = "<select name='id_sous_type'>";
        selectList += "<option value=0> Aucun sous type </option>";

        $.each( ssTypeEquipements, function( key, value ) {

            if (key == idEquipement) {

                affichage = 'ok';

                $.each( value, function( k, ssTypeName ) {
                    if (idSsType == ssTypeName['id_sous_type']) {
                        selectList += "<option value=" + ssTypeName['id_sous_type'] + " selected=selected>" + ssTypeName['name_sous_type'] + "</option>";
                    } else {
                        selectList += "<option value=" + ssTypeName['id_sous_type'] + ">" + ssTypeName['name_sous_type'] + "</option>";
                    }
                });
            }
        });

        selectList += "</select>";

        if (affichage == 'ok') {
            $('#my-container').html(selectList);
        } else {
            selectList = '';
            $('#my-container').html(selectList);
        }

    });

});

// Ajout du bouton enregistrer dans la partie admin - Map - equipements

$(document).ready(function () {
    $(".nom-cat").on('change', function(){
        let id = $(this).attr("data-id");
        $(".equip-save-"+id).css("display","inline-block");
    });
    $(".ordre-cat").on('change', function(){
        let id = $(this).attr("data-id");
        $(".equip-save-"+id).css("display","inline-block");
    });
    $(".nom-type").on('change', function(){
        let id = $(this).attr("data-id");
        $(".equip-save-"+id).css("display","inline-block");
    });
    $(".ordre-type").on('change', function(){
        let id = $(this).attr("data-id");
        $(".equip-save-"+id).css("display","inline-block");
    });
    $(".nom-ref").on('change', function(){
        let id = $(this).attr("data-id");
        $(".equip-save-"+id).css("display","inline-block");
    });
    $(".ordre-ref").on('change', function(){
        let id = $(this).attr("data-id");
        $(".equip-save-"+id).css("display","inline-block");
    });
});


// Génération select des réfs équipements dans MAP

function generateSelectCat () {

    let htmlFirstSelect = "<div class='div-ref row'>";

    htmlFirstSelect += "<div class='col-md-11'>";
    htmlFirstSelect += "<select name='ref-cat[]' class='ref-cat'>";
    htmlFirstSelect += "<option value='0'>Choisissez une catégorie</option>";

    $.each( refEquipementsCat, function( key, refEquipementCat ) {

        // On vérifie si certains sont déjà selectionné
        let activeCat = 'false';

        $(".ref-cat").each(function(){
            let selectCat = $(this).find('option:selected').text();

            if (selectCat == refEquipementCat.nom_ref_equip_cat) {
                activeCat = 'true';
            }

        });

        if (activeCat == 'false') {
            htmlFirstSelect += "<option value='" + refEquipementCat.id +"'>" + refEquipementCat.nom_ref_equip_cat +"</option>";
        }

    });

    htmlFirstSelect += "</select>";
    htmlFirstSelect += "<div class='ref-types'></div>";
    htmlFirstSelect += "</div>";
    htmlFirstSelect += "<div class='col-md-1'>";
    htmlFirstSelect += '<svg className="bi bi-trash" class="delete-div-ref" width="24px" height="24px" viewBox="0 0 16 16" fill="currentColor"\n' +
        '     xmlns="http://www.w3.org/2000/svg">\n' +
        '    <path\n' +
        '        d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z"></path>\n' +
        '    <path fill-rule="evenodd"\n' +
        '          d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"\n' +
        '          clip-rule="evenodd"></path>\n' +
        '</svg>';
    htmlFirstSelect += "</div>";
    htmlFirstSelect += "<hr class='map-ref-separator'></div>";

    $('.div-ref-equip').append(htmlFirstSelect);

}

generateSelectCat();


$(document).ready(function () {

    // Génération des menu déroulant principaux

    $(".add-ref").click(function (e) {
        e.preventDefault();
        generateSelectCat();
    });

    // on supprime la ligne
    $(".div-ref-equip").on('click', '.delete-div-ref', function(e){
        e.preventDefault();
        let refSupp = $( this ).parent().parent();
        refSupp.remove();
    });

    // Génération des sous menu déroulant
    $(".div-ref-equip").on('change', '.ref-cat', function(){

        let idType = "";
        let idCat = $( this ).val();
        let htmlSelect = '';
        let refTypes = $( this ).parent().find('.ref-types');

        $.each( refCategories, function( key, value ) {

            if (key == idCat) {

                idType = value;

                $.each( idType, function( idType, valueType ) {

                    htmlSelect += "<select name='select_ref_equip[]' id='' class='select-ref-equip' data-id='" + idType + "'>";

                    idRefs = valueType.refs;

                    htmlSelect += "<option value='0'>" + valueType.nom + "</option>";

                    $.each( idRefs, function( idRef, valueRef ) {

                        idRefDef = valueRef;

                        htmlSelect += "<option value='" + idCat + "-" + valueType.id + "-" + valueRef.id + "'>" + valueRef.nom + "</option>";

                    });

                    htmlSelect += "</select>";

                });

            }

            refTypes.html(htmlSelect);

        });

    });

});
